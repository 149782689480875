import instance from "../../axios";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getPersonThr3dAPIHistory({}, params) {
      let filter = `?document=${params.document}&currentPage=${params.currentPage}&rowsPerPage=${params.rowsPerPage}`;

      return await instance
        .get(
          `/api/Person/GetPersonThr3dAPIHistory${filter}`
        )
        .then(response => response.data.Response);
    },
    async getPersonIntegrationHistory({}, params) {
      return await instance
        .post(
          `/api/InsuranceIntegration/GetIntegrations`, params    )
        .then(response => response.data.Response);
    },
    async resentIntegration({}, params) {
      return await instance
        .post(
          `/api/InsuranceIntegration/ReSendIntegration`, params    )
        .then(response => response.data.Response);
    }
  },
  getters: {}
};