import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    edicaoDeProposta: false,
    proposta: {},
    fantasyName: false,
    limitesETaxas: {},
    propostaFinancialData: {},
    propostaFinancialDataRollback: {},
    hasCocorretagem: false,
    proposalObjects: {},
    proposalEngineExecutionLogs: [],
    observations: [],
    requestDocuments: [],
    deletedRequestDocuments: [],
    timelineHistory: [],
    particularClauses: []
  },
    mutations: {
        InsertProposalObjets(state, data) {
            state.proposalObjects = data
          },
        //Atualiza o Name do Insured para o escolhido em Nome fantasia.
        EmissionProposalType(state, data) {
            state.proposta = {
                ...state.proposta,
                EmissionProposalType: data
            };
        },
        updateFantasyName(state, data) {
            state.fantasyName = data;
        },
        updateFantasyNameProposal(state, data) {
            state.proposta = {
                ...state.proposta,
                Insured: {
                    ...state.proposta.Insured,
                    NomeSocial: data
                }
            };
        },
        limparEstado(state, data) {
            state.edicaoDeProposta = false;
            state.proposta = {};
            state.proposalObjects = {};
            state.proposalEngineExecutionLogs = [];
            state.observations = [];
            state.timelineHistory = [];
            state.requestDocuments = [];
            state.deletedRequestDocuments = [];
            state.limitesETaxas = {};
            (state.propostaFinancialData = {}),
            (state.propostaFinancialDataRollback = {});
        },

        updateParticularClauses(state, data) {
            state.particularClauses = data;
        },
        updateCorretagem(state, data) {
            state.proposta = {...state.proposta, ...data };
        },
        updateProposta(state, data) {
            state.proposta = data;
        },
        updateProposalObjects(state, data) {
            state.proposalObjects = data;
        },
        updateProposalEngineExecutionLogs(state, data) {
            state.proposalEngineExecutionLogs = data;
        },
        updateObservations(state, data) {
            state.observations = data;
        },
        updateTimelineHistory(state, data) {
            state.timelineHistory = data;
        },
        updateRequestDocuments(state, data) {
            state.requestDocuments = data;
        },
        updateDeletedRequestDocuments(state, data) {
            state.deletedRequestDocuments = data;
        },
        updateTomadorProposta(state, data) {
            state.proposta = {
                ...state.proposta,
                PolicyHolder: data,
                PolicyHolderId: (data || {}).Id
            };
            state.limitesETaxas = {};
        },
        updateTomadorPropostaSemAlterarLimiteETaxa(state, data) {
            state.proposta = {
                ...state.proposta,
                PolicyHolder: data,
                PolicyHolderId: (data || {}).Id
            };
        },
        updateSeguradoProposta(state, data) {
            state.proposta = {
                ...state.proposta,
                Insured: data,
                InsuredId: (data || {}).Id
            };
        },
        updateTomadorFilialProposta(state, filial) {
            state.proposta = {
                ...state.proposta,
                PolicyHolderBranch: filial,
                PolicyHolderBranchId:
                    (filial || {}).PersonId > 0 ? filial.PersonId : undefined
            };
        },
        updateLimitesETaxas(state, limitesETaxas) {
            state.limitesETaxas = limitesETaxas;
        },
        updatePropostaFiliaisTomador(state, filiais) {
            state.proposta = {
                ...state.proposta,
                PolicyHolder: {
                    ...state.proposta.PolicyHolder,
                    Branches: filiais
                }
            };
        },
        updatePropostaEnderecoSegurado(state, endereco) {
            state.proposta = {
                ...state.proposta,
                InsuredLocation: endereco,
                InsuredLocationId: endereco.Id
            };
        },
        updatePropostaEnderecosSegurado(state, enderecos) {
            state.proposta = {
                ...state.proposta,
                Insured: {
                    ...state.proposta.Insured,
                    Locations: enderecos
                }
            };
        },
        updatePropostaFinancialData(state, data) {
            state.propostaFinancialData = data;
        },
        updatePropostaFinancialDataRollback(state, data) {
            state.propostaFinancialDataRollback = data;
        },
        updateEdicaoDeProposta(state, data) {
            state.edicaoDeProposta = data;
        },
        updateBeneficiariesProposal(state, data) {
            state.proposta = {
                ...state.proposta,
                Beneficiaries: data
            };
        },
        adicionaCocorretagem(state, data) {
            state.proposta = {
                ...state.proposta,
                CoBrokers: data
            };
        },
        clearCocorretagem(state, data) {
            state.proposta = {
                ...state.proposta,
                CoBrokers: []
            };
        },
        updateHasCobrokers(state, data){
          state.proposta = {
            ...state.proposta,
            HasCoBrokers: data
          }
        }
    },
    actions: {
        adicionaCocorretagem({ commit }, data) {
            data.map(v => {
                delete v.changedValue;
                delete v.cnpj;
                delete v.corretorCount;
                delete v.id;
                delete v.razaoSocial;
            });
            commit("adicionaCocorretagem", data);
        },
        limparEstado({ commit }, data) {
            commit("limparEstado", {});
        },
        setProposal({ commit }, data) {
            commit("updateProposta", data);
            commit("updateEdicaoDeProposta", true);
        },
        async postUpdateProposal({}, payload) {
            return await instance
                .post("api/proposal/UpdateProposal", payload)
                .then(response => response.data.Response);
        },

        async loadEndorsementInfoByUniqueId({ commit }, payload) {
            return await instance
                .get(`/api/kanban/GetCardEndorsementInfo?ProposalUniqueId=${payload}`)
                .then(resp => {
                    commit("updateProposta", resp.data.Response.Proposal);
                    commit("updateObservations", resp.data.Response.Observations);
                    commit("updateProposalObjects", resp.data.Response.ProposalObjects);
                    commit(
                        "updateProposalEngineExecutionLogs",
                        resp.data.Response.ProposalEngineExecutionLogs
                    );
                    commit("updateRequestDocuments", resp.data.Response.RequestDocuments);
                    commit(
                        "updateDeletedRequestDocuments",
                        resp.data.Response.DeletedRequestDocuments
                    );
                    commit("updateTimelineHistory", resp.data.Response.TimelineHistory);
                    commit("updateEdicaoDeProposta", true);
                    return resp.data.Response;
                });
        },
        async loadProposalInfosByUniqueId({ commit }, id) {
            return await instance
                .get(`/api/kanban/GetCardProposalInfo?ProposalUniqueId=${id}`)
                .then(resp => {
                    commit("updateProposta", resp.data.Response.Proposal);
                    commit("updateObservations", resp.data.Response.Observations);
                    commit("updateProposalObjects", resp.data.Response.ProposalObjects);
                    commit(
                        "updateProposalEngineExecutionLogs",
                        resp.data.Response.ProposalEngineExecutionLogs
                    );
                    commit("updateRequestDocuments", resp.data.Response.RequestDocuments);
                    commit(
                        "updateDeletedRequestDocuments",
                        resp.data.Response.DeletedRequestDocuments
                    );
                    commit("updateTimelineHistory", resp.data.Response.TimelineHistory);
                    commit("updateEdicaoDeProposta", true);
                    return  resp.data.Response;
                });
        },
        async getValidateRetroactivityDays({}, payload) {
            return await instance
                .get(
                    `api/Proposal/ValidateRetroactivityDays?ProposalUniqueId=${payload}`
                )
                .then(response => response.data.Response);
        },
        async loadPropostaByUniqueId({ commit }, id) {
            return await instance
                .get(`api/Proposal/GetProposalByUniqueId2?ProposalUniqueId=${id}`)
                .then(response => response.data.Response)
                .then(data => {
                    commit("updateProposta", data);
                    commit("updateEdicaoDeProposta", true);
                    return data;
                });
        },
        async validaTomadorPorCnpj({ commit }, cnpj) {
            return await instance
                .post(
                    `api/PolicyHolder/ValidatePolicyHolderByCNPJToNewProposal?Cnpj=${cnpj}`
                )
                .then(response => response.data.Response)
                .then(data => commit("updateTomadorProposta", data) || data);
        },
        async buscaTomadorPorTexto({ commit }, texto) {
            return await instance
                .get(
                    `/api/PolicyHolder/SearchPolicyHolder?TextSearch=${texto}&IgnoreInactive=true`
                )
                .then(response => response.data.Response);
        },
        async validateRequiredProposalDocuments({ commit }, proposalUniqueId) {
            return  await instance
                .get(
                    `/api/Proposal/GetPendingRequiredRequestDocuments?uniqueId=${proposalUniqueId}`
                )
                .then(data => (data.data || {}).Response);
        },
        async carregarLimitesETaxas({ commit, getters }) {
            return await instance
                .post(
                    `/api/PolicyHolder/CalculatePolicyHolderLimitsAndRates?PolicyHolderUniqueId=${getters.proposta.PolicyHolder.UniqueId}`
                )
                .then(response => {
                    commit("updateLimitesETaxas", response.data.Response);
                    return response.data.Response;
                });
        },
        async solicitarNomeacaoTomador({ commit }, data) {
            return await instance({
                method: "post",
                url: "/api/PolicyHolder/RegisterPolicyHolderNomeationRequest",
                data
            }).then(response => response.data.Response);
        },

        async uploadDocumentoByProposal({ commit }, data) {
            return await instance({
                method: "post",
                url: "api/Proposal/SaveProposalRequestDocument",
                data
            }).then(response => response.data.Response);
        },

        async deleteDocumentProposal({ commit }, data) {
            return await instance({
                method: "post",
                url: "api/Proposal/ProposalDeleteRequestDocument",
                data
            }).then(response => response.data.Response);
        },

        async createDocumentProposal({ commit }, data) {
            return  await instance({
                method: "post",
                url: "api/Proposal/CreateProposalRequestDocument",
                data
            }).then(response => response.data.Response);
        },

        async uploadDocumentProposal({ commit }, data) {
            var formData = new FormData();
            formData.append("file", data);
            return await instance
                .post("api/Archive/Upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(response => response.data.Response);
        },

        async editNameDocumentProposal({ commit }, data) {
            return await instance({
                method: "post",
                url: "api/Proposal/ProposalChangeRequestDocumentName",
                data
            }).then(response => response.data.Response);
        },
        async restoreDocumentProposal({ commit }, data) {
            return await instance({
                method: "post",
                url: "api/Proposal/ProposalRestoreRequestDocument",
                data
            }).then(response => response.data.Response);
        },
        async getDocumentoPorId({ commit }, id) {
            return await instance
                .get(
                    `/api/Broker/GetBrokerRequestDocumentArchives?RequestDocumentUniqueId=${id}`
                )
                .then(response => response.data.Response);
        },
        async getUpdateFinancialData({}, payload) {
            return await instance
                .post(`/api/Proposal/UpdateFinancialData`, payload)
                .then(response => response.data.Response);
        },

        async calcularDadosFinanceirosProposta({ commit, getters }, data) {
            data = data || {};
            const proposta = getters.proposta;
            const propostaFinancialDataRollback =
                getters.propostaFinancialDataRollback;
            const propostaFinancialData = getters.propostaFinancialData || {};
            const requestObj = {
                ...(data || {}),
                ProposalUniqueId: proposta.UniqueId,
                PolicyHolderUniqueId: data.PolicyHolderUniqueId || proposta.PolicyHolder.UniqueId,
                ProposalStartDate: data.StartDate || proposta.StartDate,
                ProposalEndDate: data.EndDate || proposta.EndDate,
                DeadlineDays: data.DeadlineDays || proposta.DeadlineDays,
                InsuredAmountValue: data.InsuredAmountValue || proposta.InsuredAmountValue,
                ComissionValue: data.ComissionValue >= 0 ?
                    data.ComissionValue :
                    proposta.ComissionValue,
                ComissionPercentage: data.ComissionPercentage || proposta.ComissionPercentage,
                Tax: data.Tax || proposta.RiskRate,
                InsurancePremium: data.InsurancePremium || proposta.InsurancePremium,

                FirstComissionValue: propostaFinancialDataRollback.ComissionValue ||
                    proposta.ComissionValue,
                FirstComissionPercentage: propostaFinancialDataRollback.ComissionPercentage ||
                    proposta.ComissionPercentage,
                FirstTax: propostaFinancialDataRollback.Tax || proposta.RiskRate,
                FirstInsurancePremium: propostaFinancialDataRollback.InsurancePremium ||
                    proposta.InsurancePremium,
                MinInsurancePremium: proposta.MinInsurancePremium,
                CalculatePerComissionPercentage:
                    (data || {}).CalculatePerComissionPercentage !== undefined ?
                    (data || {}).CalculatePerComissionPercentage :
                    true
            };
            return await instance({
                    method: "post",
                    url: "api/Proposal/CalculateFinancialData",
                    data: requestObj
                })
                .then(response => response.data.Response)
                .then(data => {
                    commit("updatePropostaFinancialData", data);
                    //Valor inicial de tudo. Vamos salvar ele no rollback. Caso de algum erro, voltamos esses dados.
                    if (!propostaFinancialDataRollback.Tax) {
                        //Salvando dados de rollback
                        //Na primeira vez esse objeto é vazio. Então salvamos os dados dele para sempre enviar com a proposta.
                        commit("updatePropostaFinancialDataRollback", data);
                    }

                    const prop = {...getters.proposta };
                    prop.Tax = data.Tax;
                    prop.InsurancePremium = data.InsurancePremium;
                    prop.ComissionValue = data.ComissionValue;
                    prop.RiskRate = data.Tax;
                    prop.ComissionPercentage = data.ComissionPercentage;

                    if (!prop.NumberOfInstallment || prop.NumberOfInstallment == 0) {
                        prop.NumberOfInstallment = 1;
                    }

                    if (!prop.GracePeriod || prop.GracePeriod == 0) {
                        if (
                            ((data.InstallmentResult || {}).PossibleGracePeriodsInDays || [])
                            .length > 0
                        )
                            prop.GracePeriod =
                            data.InstallmentResult.PossibleGracePeriodsInDays[0];
                        else prop.GracePeriod = undefined;
                    }

                    commit("updateProposta", prop);
                    commit("updateEdicaoDeProposta", false);

                    return data;
                })
                .catch(ex => {
                    commit("updatePropostaFinancialData", {
                        ...getters.propostaFinancialDataRollback,
                        UpdatedDate: new Date()
                    });
                    throw ex;
                });
        },
        async calcularDadosFinanceirosPropostaSeguradora({ commit, getters }, data) {
            data = data || {};
            const proposta = getters.proposta;
            const propostaFinancialDataRollback =
                getters.propostaFinancialDataRollback;
            const propostaFinancialData = getters.propostaFinancialData || {};
            const requestObj = {
                ...(data || {}),
                ProposalUniqueId: proposta.UniqueId,
                PolicyHolderUniqueId: data.PolicyHolderUniqueId || proposta.PolicyHolder.UniqueId,
                ProposalStartDate: data.StartDate || proposta.StartDate,
                ProposalEndDate: data.EndDate || proposta.EndDate,
                DeadlineDays: data.DeadlineDays || proposta.DeadlineDays,
                InsuredAmountValue: data.InsuredAmountValue || proposta.InsuredAmountValue,
                ComissionValue: data.ComissionValue >= 0 ?
                    data.ComissionValue :
                    proposta.ComissionValue,
                ComissionPercentage: data.ComissionPercentage || proposta.ComissionPercentage,
                Tax: data.Tax || proposta.RiskRate,
                InsurancePremium: data.InsurancePremium || proposta.InsurancePremium,

                FirstComissionValue: propostaFinancialDataRollback.ComissionValue ||
                    proposta.ComissionValue,
                FirstComissionPercentage: propostaFinancialDataRollback.ComissionPercentage ||
                    proposta.ComissionPercentage,
                FirstTax: propostaFinancialDataRollback.Tax || proposta.RiskRate,
                FirstInsurancePremium: propostaFinancialDataRollback.InsurancePremium ||
                    proposta.InsurancePremium,
                MinInsurancePremium: proposta.MinInsurancePremium,
                CalculatePerComissionPercentage:
                    (data || {}).CalculatePerComissionPercentage !== undefined ?
                    (data || {}).CalculatePerComissionPercentage :
                    true,
                CalculatePerRiskRate:
                    (data || {}).CalculatePerRiskRate !== undefined ?
                    (data || {}).CalculatePerRiskRate :
                    false
            };
            return await instance({
                    method: "post",
                    url: "api/Proposal/UpdateCalculateFinancialData",
                    data: requestObj
                })
                .then(response => response.data.Response)
                .then(data => {
                    commit("updatePropostaFinancialData", data);
                    //Valor inicial de tudo. Vamos salvar ele no rollback. Caso de algum erro, voltamos esses dados.
                    if (!propostaFinancialDataRollback.Tax) {
                        //Salvando dados de rollback
                        //Na primeira vez esse objeto é vazio. Então salvamos os dados dele para sempre enviar com a proposta.
                        commit("updatePropostaFinancialDataRollback", data);
                    }

                    const prop = {...getters.proposta };
                    prop.Tax = data.Tax;
                    prop.InsurancePremium = data.InsurancePremium;
                    prop.ComissionValue = data.ComissionValue;
                    prop.RiskRate = data.Tax;
                    prop.ComissionPercentage = data.ComissionPercentage;

                    if (!prop.NumberOfInstallment || prop.NumberOfInstallment == 0) {
                        prop.NumberOfInstallment = 1;
                    }

                    if (!prop.GracePeriod || prop.GracePeriod == 0) {
                        if (
                            ((data.InstallmentResult || {}).PossibleGracePeriodsInDays || [])
                            .length > 0
                        )
                            prop.GracePeriod =
                            data.InstallmentResult.PossibleGracePeriodsInDays[0];
                        else prop.GracePeriod = undefined;
                    }

                    commit("updateProposta", prop);
                    commit("updateEdicaoDeProposta", false);

                    return data;
                })
                .catch(ex => {
                    commit("updatePropostaFinancialData", {
                        ...getters.propostaFinancialDataRollback,
                        UpdatedDate: new Date()
                    });
                    throw ex;
                });
        },
        async sendProposalAdjustment({ commit }, _data) {
            return await instance({
                    method: "post",
                    url: "api/Proposal/RequestProposalAdjustment",
                    data: _data
                })
                .then(response => response.data.Response)
                .then(data => commit("updateProposta", data) || data);
        },
        async validaSeguradoPorCnpj({ commit }, data) {
            return await instance({
                    method: "post",
                    url: "/api/Insured/ValidateInsuredByDocumentToNewProposal",
                    data
                })
                .then(response => {
                    return response.data.Response;
                })
                .then(data => {
                    commit("updateSeguradoProposta", data);
                    if (data.Locations && data.Locations.length > 0) {
                        const foundMain = data.Locations.find(loc => loc.IsMain === true);

                        commit("updatePropostaEnderecoSegurado", foundMain);
                    }
                    return data;
                });
        },
        async buscaSeguradoPorTexto({ commit }, texto) {
            return await instance
                .get(`/api/Insured/SearchInsured?TextSearch=${texto}`)
                .then(response => {
                    return response.data.Response;
                });
        },
        async updateProposalTimelineInfo({ commit }, data) {
            return await instance({
                method: "post",
                url: "/api/Proposal/UpdateProposalTimelineInfo",
                data
            }).then(response => response.data.Response);
        },
        async getContractualTerms({}, proposalUniqueId) {
            return await instance
                .get(`/api/Proposal/GetProposalContractualTerms`, {
                    params: { proposalUniqueId }
                })
                .then(response => response.data.Response);
        },

        async getCompanyProposalTags() {
            return await instance
                .get("/api/Proposal/GetCompanyProposalTags")
                .then(response => response.data.Response);
        },

        async getProposalRiskChange({ commit }, proposalUniqueId) {
            return await instance
                .get(
                    `/api/Proposal/GetProposalRiskChange?ProposalUniqueId=${proposalUniqueId}`
                )
                .then(data => (data.data || {}).Response);
        },

        async reseteChanges({ commit }, proposalUniqueId) {
            return await instance
                .post(
                    `/api/Proposal/ResetProposalRiskChange?ProposalUniqueId=${proposalUniqueId}`
                )
                .then(data => (data.data || {}).Response);
        },

        async getEndossoByPolicyUniqueId({commit},policyUniqueId){
            return await instance
                .get(
                    `/api/endorsement/GetPolicyEndorsementsFromPolicy?policyUniqueId=${policyUniqueId}`
                )
                .then(data => (data.data || {}).Response);
        }
    },
    getters: {
        proposta(state) {
            return state.proposta;
        },
        proposalObjects(state) {
            return state.proposalObjects;
        },
        proposalObservations(state) {
            return state.observations;
        },
        proposalEngineExecutionLogs(state) {
            return state.proposalEngineExecutionLogs;
        },
        proposalDocuments(state) {
            return state.requestDocuments;
        },
        proposalDeletedDocuments(state) {
            return state.deletedRequestDocuments;
        },
        proposalTimelineHistory(state) {
            return state.timelineHistory;
        },
        edicaoDeProposta(state) {
            return state.edicaoDeProposta;
        },
        tomadorDaProposta(state) {
            return (state.proposta || {}).PolicyHolder || {};
        },
        seguradoDaProposta(state) {
            return (state.proposta || {}).Insured || {};
        },

        limitesETaxas(state) {
            return state.limitesETaxas;
        },
        propostaFinancialData(state) {
            return state.propostaFinancialData;
        },
        propostaFinancialDataRollback(state) {
            return state.propostaFinancialDataRollback;
        },
        cocorretagem(state) {
            return {
                hasCocorretagem: state.hasCocorretagem,
                cocorretagem: state.cocorretagem
            };
        },
        particularClauses(state) {
            return state.particularClauses;
        }
    }
}
